<template>
  <div class="page">
    <header>
      <div class="logo">
        <h1>Admin</h1>
      </div>
      <div class="spacer"></div>
      <div class="top-right-options">
        <a href="#" @click.prevent="logout">logout</a>
      </div>
    </header>

    <section class="middle">
      <aside>
        <ul>
          <li v-for="(item, idx) in menu" :key="idx">
            <strong v-if="item.path === $route.path">{{ item.label }}</strong>
            <router-link v-else-if="item.path" :to="item.path">
              {{ item.label }}
            </router-link>
            <template v-else>{{ item.label }}</template>
          </li>
          <li>
            <a href="/minion">Minion</a>
          </li>
        </ul>
      </aside>

      <main>
        <slot></slot>
      </main>
    </section>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import myaxios from '../libs/myaxios';
import auth from '../libs/auth';

const menu = [
  { label: 'Overview', path: '/' },
  { label: 'Servers', path: '/servers' },
  { label: 'Encoders', path: '/encoders' },
  { label: 'MinIOs', path: '/minios' },
  { label: 'Users', path: '/users' },
  { label: 'Files', path: '/files' },
  { label: 'Stats', path: null },
  { label: 'Proxies', path: '/proxies' },
  { label: 'Uploads & Jobs', path: '/uploads' },
  { label: 'Dmca', path: '/dmca' },
  { label: 'Payouts', path: '/payouts' },
  { label: 'Settings', path: '/settings' },
  { label: 'Support', path: '/support' },
  // { label: 'Encoder load balancing', path: '/encoder_lb' },
  { label: 'Happy hour', path: '/happy_hour' },
];

const router = useRouter();

async function logout() {
  await myaxios.post('/auth/logout');
  auth.hasCalledAuthenticate = false;
  await router.push('/login');
}
</script>

<style lang="scss" scoped>
.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  border-bottom: 1px solid #E0DAD7;
  display: flex;

  & > * {
    display: flex;
    align-items: center;
  }

  .logo{
    padding: 0 10px;

    h1 {
      margin: 0;
    }
  }

  .spacer {
    flex-grow: 1;
  }

  .top-right-options {
    padding: 0 10px;
  }
}

section.middle {
  flex-grow: 1;

  display: flex;

  aside {
    border-right: 1px solid #E0DAD7;
    width: 300px;

    ul {
      list-style-type: none;
    }
  }

  main {
    flex-grow: 1;

    padding: 10px;
  }
}
</style>
