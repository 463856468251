<template>
  <div>
    <h3>Servers</h3>

    <MyModal v-if="modalVisible">
      <template #header>
        {{ modalFormMode === 'add' ? 'Add' : 'Edit '}}
        Server
      </template>

      <global-events @keydown.esc="modalVisible = false" />

      <form @submit.prevent="submitModal(modalFormMode)" id="form">
        <table class="form">
          <tr v-if="modalForm.id">
            <th>ID:</th>
            <td>
              <input type="text" v-model="modalForm.id" disabled />
            </td>
          </tr>
          <tr>
            <th>
              Mnemonic Name
              <span @click="mnemonicEditable = !mnemonicEditable" class="clickable">&#x270d;</span>:
            </th>
            <td>
              <input type="text" v-model.trim="modalForm.mnemonic_name"
                pattern="[a-z][a-z0-9\-]*[a-z0-9]" required :disabled="!mnemonicEditable">
            </td>
          </tr>
          <tr>
            <th>
              User-facing hostname
              (<a @click.prevent="generateHostname('hostname')" href="#">auto-generate</a>)
              <span @click="hostnameEditable = !hostnameEditable" class="clickable">&#x270d;</span>:
            </th>
            <td>
              <input type="text" v-model.trim="modalForm.hostname"
                pattern="^[a-z][a-z0-9]*" required :disabled="!hostnameEditable">
            </td>
          </tr>
          <tr>
            <th>Server role:</th>
            <td>
              <select v-model="modalForm.type" style="width: 100%;" required>
                <option :value="''" disabled>-- Select role --</option>
                <option v-for="type in ['ENCODER', 'PROXY', 'MINIO']" :key="type">{{type}}</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>IP internal:</th>
            <td>
              <input type="text" v-model.trim="modalForm.ip_int"
                pattern="[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}" required>
            </td>
          </tr>
          <tr>
            <th>IP external:</th>
            <td>
              <input type="text" v-model.trim="modalForm.ip_ext"
                pattern="[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}" required>
            </td>
          </tr>
          <tr>
            <th>Internal network speed (in Gbps):</th>
            <td>
              <input type="number" v-model.number="modalForm.net_int"
                min="1" max="100" step="0.1" required>
            </td>
          </tr>
          <tr>
            <th>External network incoming speed (in Gbps):</th>
            <td>
              <input type="number" v-model.number="modalForm.net_ext_rx"
                min="1" max="100" step="0.1" required>
            </td>
          </tr>
          <tr>
            <th>External network outgoing speed (in Gbps):</th>
            <td>
              <input type="number" v-model.number="modalForm.net_ext_tx"
                min="1" max="100" step="0.1" required>
            </td>
          </tr>
          <tr>
            <th>Storage space (in Gigabytes):</th>
            <td>
              <input type="number" v-model.number="modalForm.storage_space"
                min="10" max="30000" step="0.1" required>
            </td>
          </tr>
        </table>

      </form>

      <template #footer>
        <button @click="modalVisible = false">Cancel</button>&#x200b;
        <button type="submit" form="form">Submit</button>
      </template>
    </MyModal>

    <p>
      Refresh interval for "on?" field:
      <input type="range" min="1" max="10" v-model="refreshInterval">
      {{ refreshInterval }} secs
    </p>

    <p>
      <button @click="openAddModal">Add Server</button>
    </p>

    <SortableTable :rows="fullServers" :fields="[
      ['id', 'ID'],
      ['mnemonic_name', 'Mnemonic name'],
      ['hostname', 'User hostname'],
      ['type', 'Type'],
      ['isOnline', 'On?'],
      ['ip_int', 'IP internal'],
      ['ip_ext', 'IP external'],
      ['net_int', 'Int net b/w'],
      ['net_ext_rx', 'Ext net RX'],
      ['net_ext_tx', 'Ext net TX'],
      ['storage_space', 'Storage space'],
      ['lbProb', 'Prob'],
      ['actions', ''],
    ]">
      <template #field-isOnline="{value}">
        <span :style="{color: value ? 'green' : 'red'}" style="font-weight: bold">
          {{ value ? 'yes' : 'no' }}
        </span>
      </template>
      <template #field-net_int="{value}">
        {{ value / 1_000_000_000 }} Gbps
      </template>
      <template #field-net_ext_rx="{value}">
        {{ value / 1_000_000_000 }} Gbps
      </template>
      <template #field-net_ext_tx="{value}">
        {{ value / 1_000_000_000 }} Gbps
      </template>
      <template #field-storage_space="{value}">
        {{ value / 1_000_000_000 }} GB
      </template>
      <template #field-lbProb="{value}">
        <div style="text-align: right">
          {{ value }}%
        </div>
      </template>
      <template #field-actions="{row}">
        <span class="clickable unicode-icon" style="color: blue;"
              @click="openEditModal(serversByName[row.mnemonic_name])">
          &#x270d;
        </span>
        &nbsp;&nbsp;&nbsp;
        <span class="clickable unicode-icon" style="color: red;"
              @click="deleteServer(serversByName[row.mnemonic_name])">
          &times;
        </span>
      </template>
    </SortableTable>

    <p>
      <button @click="openAddModal">Add Server</button>
    </p>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useIntervalFn } from '@vueuse/core';
import myaxios from '../libs/myaxios';
import { getScriptPassword } from '../libs/auth';
import { dclone } from '../libs/various';
import SortableTable from '@/components/SortableTable.vue';

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:layout']);
emit('update:layout', 'default');

const servers = ref([]);
const modalVisible = ref(false);
const modalForm = ref({});
const modalFormMode = ref(null);
const hostnameEditable = ref(false);
const mnemonicEditable = ref(false);
const refreshInterval = ref(5);
const serversOnline = ref({}); // mnemonic_name => 1
const lb = ref({}); // type => [...servers]
const serversByName = computed(() => Object.fromEntries(
  servers.value.map((server) => [server.mnemonic_name, server]),
));

const lbTable = computed(() => {
  const ret = {};
  Object.values(lb.value).forEach((serversArray) => {
    let p = 0;
    // eslint-disable-next-line camelcase
    serversArray.forEach(({ p_level, server }) => {
      // eslint-disable-next-line camelcase
      ret[server.mnemonic_name] = p_level - p;
      // eslint-disable-next-line camelcase
      p = p_level;
    });
  });
  return ret;
});

const fullServers = computed(
  () => servers.value.map((server) => {
    const ret = { ...server };
    ret.isOnline = !!serversOnline.value[server.mnemonic_name];
    ret.lbProb = (lbTable.value[server.mnemonic_name] ?? 0) * 100;
    return ret;
  }),
);

const allModalFields = [
  'id', 'mnemonic_name', 'hostname', 'type', 'ip_int', 'ip_ext', 'net_int',
  'net_ext_rx', 'net_ext_tx', 'storage_space',
];

function openAddModal() {
  modalForm.value = Object.fromEntries(
    allModalFields.filter((f) => f !== 'id').map((f) => [f, f === 'type' ? '' : null]),
  );
  modalFormMode.value = 'add';
  hostnameEditable.value = true;
  mnemonicEditable.value = true;
  modalVisible.value = true;
}

function openEditModal(server) {
  modalForm.value = Object.fromEntries(
    allModalFields.map((f) => [f, server[f]]),
  );
  ['net_int', 'net_ext_rx', 'net_ext_tx', 'storage_space'].forEach((fieldName) => {
    modalForm.value[fieldName] /= 1_000_000_000;
  });
  modalFormMode.value = 'edit';
  hostnameEditable.value = false;
  mnemonicEditable.value = false;
  modalVisible.value = true;
}

async function getServers() {
  try {
    ({ data: servers.value } = await myaxios.get('/servers'));
  } catch (err) {
    // eslint-disable-next-line no-alert
    alert("Couldn't download list");
  }
}

async function submitModal() {
  // last validation
  let mf = modalForm.value;
  mf = dclone(mf); // don't modify the source object
  ['net_int', 'net_ext_rx', 'net_ext_tx', 'storage_space'].forEach((fieldName) => { mf[fieldName] *= 1_000_000_000; });
  const [scriptPassword, mode, scriptDir] = await getScriptPassword();
  let command = `MOJO_MODE=${mode} ${scriptDir}/sf-admin-modify-servers.pl ${scriptPassword} ${modalFormMode.value}-server`;
  command += Object.entries(mf).reduce((acc, [key, value]) => `${acc} ${key}=${value}`, '');

  // eslint-disable-next-line no-alert
  alert(`The command is:\n\n${command}`);
}

async function deleteServer(server) {
  // eslint-disable-next-line no-restricted-globals,no-alert
  if (!confirm(`Are you sure you want to delete server #${server.id} with mnemonic name = "${server.mnemonic_name}" and user hostname = "${server.hostname}"?`)) return;
  const [scriptPassword, mode, scriptDir] = await getScriptPassword();
  const command = `MOJO_MODE=${mode} ${scriptDir}/sf-admin-modify-servers.pl ${scriptPassword} remove-server id=${server.id}`;

  // eslint-disable-next-line no-alert
  alert(`The command is:\n\n${command}`);
}

async function generateHostname(fieldName) {
  if (!hostnameEditable.value) return;
  try {
    const { data: hostname } = await myaxios.get('/servers/generate_hostname');
    modalForm.value[fieldName] = hostname;
  } catch (err) {
    // eslint-disable-next-line no-alert
    alert("Couldn't get random hostname from server");
  }
}

async function getIsOnline() {
  try {
    [
      { data: serversOnline.value },
      { data: lb.value },
    ] = await Promise.all([
      myaxios.get('/servers/online'),
      myaxios.get('/servers/lb'),
    ]);
  } catch (err) {
    // eslint-disable-next-line no-alert
    alert("Couldn't get online status from server");
  }
}

getServers();
useIntervalFn(getIsOnline, () => refreshInterval.value * 1_000, {
  immediateCallback: true,
});
</script>

<style lang="scss" scoped>
table.form {
  th {
    text-align: right;
  }
  td {
    input:invalid, input:required:invalid, select:required:invalid {
      background-color: pink;
    }
    input:valid, select:valid {
      background-color: #cfc;
    }
    input[type=number] {
      width: 100%;
    }
  }
}
</style>
